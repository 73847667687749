/* Import Font from Google Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* end Import Font from Google Font */

/* ======================================================================== */
/* ======================================================================== */

body {
  font-family: "Poppins", sans-serif;
}

.square-image {
  width: 100%; /* Ubah sesuai ukuran yang diinginkan */
  height: 230px; /* Sama dengan lebar untuk memastikan persegi */
  object-fit: cover; /* Memastikan gambar tidak terdistorsi */
  display: block; /* Opsional, menghapus jarak bawaan jika gambar adalah inline */
  border-radius: 0; /* Opsional, memastikan sudut tidak melengkung */
}

/* .swap-navbar {
  background-color: #f36a33;
} */

.swap-our-impact {
  background-color: #f36a33;
}
.swap-our-impact .card-body {
  background-color: #f36a33;
}
.swap-our-impact .swap-box-icon-impact {
  color: #f36a33;
}

.swap-box-project {
  background-color: #f36a33;
}

.swap-partner-testimoni {
  background-color: #f36a33;
}

.swap-who-are-quotes {
  background-color: #f36a33;
  padding: 150px 0;
}
.swap-who-are-quotes h1 {
  font-size: 80px;
  font-weight: 600;
  line-height: 120px;
}

.swap-who-we-are-box-visimisi {
  background-color: #f36a33;
}
.swap-who-we-are-box-visimisi p {
  text-align: justify;
  font-size: 20px;
}
.swap-who-we-are-box-visimisi .card-header {
  background-color: #e8af20;
  border-bottom: 3px solid #ffffff;
}

.box-team:hover {
  background-color: #e9e9e9;
  cursor: pointer;
  box-shadow: 5px 5px #dcdcdc;
}

.body-content-image {
  display: flex;
  justify-content: center;
}
.body-content-image img {
  width: 70%;
  margin-bottom: 20px;
  border-radius: 10px;
}

@media only screen and (max-width: 920px) {
  .swap-who-are-quotes h1 {
    font-size: 50px;
    line-height: 70px;
  }
  .swap-our-impact h3 {
    margin-bottom: 40px !important;
  }
  .swap-our-impact .card-body {
    padding: 20px !important;
  }

  .icon-impact {
    display: block !important;
    width: 80px !important;
    margin: auto !important;
    margin-bottom: 20px !important;
    padding: 10px 0;
  }
  .box-impact-icon {
    width: 50% !important;
  }

  .box-image-visimisi {
    height: 200px !important;
    width: 94%;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 20px;
    -webkit-box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.21);
  }

  .body-content-image img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }
}
